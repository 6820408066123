:root {
    /* Breakpoints */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    /* Flex paddings  and margins*/
    --padding: 1rem;
    --vertical-padding: 1rem;
    --margin: .5rem;
    --vertical-margin: .5rem;
    /* Fixed header */
    --fixed-header-height: 10vh;
    /* Slick */
    --slick-slider-height: 20vh;
    /* Colors */
    --text-color: #121212;
    --main-color: #cdcdcd;
    --accent-color: #E20613;
    --effect-color: #E20613;
    --header-text-color: #fff;
    --header-bg-color: inherit;
    --footer-bg-color: #1F487E;
    --footer-text-color: #fff;
    --menu-bg-color: #1F487E;
    --menu-text-color: #fff;
    --menu-text-hover-color: #fff;
    --menu-text-bg-color: inherit;
    --menu-text-bg-hover-color: #17255A;
    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: Muli, sans-serif;
    --alternative-font-family: var(--base-font-family);
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/Muli.css";

/*--------------------------------------------------------------
General
--------------------------------------------------------------*/

html {
    height: 100%;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-weight: 400;
    font-family: var(--base-font-family);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    color: var(--text-color);
}

header {
    background: var(--header-bg-color);
    color: var(--header-text-color);
   
}

footer {
    text-align: center;
    padding: 1rem 0;
    background: var(--footer-bg-color);
    color: var(--footer-text-color);
}

header a {
    text-decoration: none;
}

.logo {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    font-weight: 300;
    color: #1F487E;
}

.logo a {
    padding: 1rem;
}

.logo span:last-of-type {
    display: block;
    font-size: calc(1 * 1)rem;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}
        
/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/
h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    font-family: var(--alternative-font-family);
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 300;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
    font-family: var(--alternative-font-family);
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 300;
}

h3 {
    font-size: 1rem;
    font-family: var(--alternative-font-family);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/*--------------------------------------------------------------
Menu
--------------------------------------------------------------*/

nav {
    background: var(--menu-bg-color);
}

.menu.menu li {
    margin: 0;
}

.menu.menu li a {
    padding: .5rem .8rem;
    color: var(--menu-text-color);
    background: var(--menu-text-bg-color);
}

.menu.menu li.active a, .menu.menu li a:hover {
    background: var(--menu-text-bg-hover-color);
    color: var(--menu-text-hover-color);
}

/*--------------------------------------------------------------
Slick slider
--------------------------------------------------------------*/

.slick-slider {
    margin: 0;
}

.slick-track {
    display: flex;
    flex-direction: row;
}

.slick-initialized .slick-slide, .slick-substitute div {
    background-size: cover;
    background-position: top center;
    display: flex;
    height: 100%;
    min-height: var(--slick-slider-height);
}

.slider-title {
    max-width: var(--container-max-width);
    text-align: center;
}
/*--------------------------------------------------------------
Flexbox sticky footer by MW (add height:100% to html)
--------------------------------------------------------------*/

.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%;
 /* IE fix */;
}

header, footer {
    flex: none;
}

/*--------------------------------------------------------------
Forms
--------------------------------------------------------------*/

.boltform *, .boltform input[type] {
    border-radius: 0px;
}

.boltform {
    background: var(--main-color);
    color: var(--header-text-color);
    padding: 1rem;
    margin-top: 1.5rem;
}

.boltform input, .boltform textarea {
    width: 100%;
}

.boltform button {
    background: var(--menu-text-bg-hover-color);
    color: var(--menu-text-hover-color);
    border: none;
    margin: 0;
}

.boltform button:hover {
    background: color(var(--menu-text-bg-hover-color) lightness(60%));
    color: var(--menu-text-hover-color);
}

.boltform .boltforms-row label.required:after {
    content: " *";
    color: var(--menu-text-bg-hover-color);
}

.intenditfooter {
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(255,255,255,0.4);
    margin: 0 .1rem 0 0;
    text-align: right;
}

.intenditfooter a {
    text-decoration: none;
}

/*--------------------------------------------------------------
Maps
--------------------------------------------------------------*/

.map-canvas {
    height: 35vh;
}

.map-canvas img {
}

.homepage article {

column-count: 2;
column-gap: 15px;
margin-bottom: 2rem;
}
.homepage article h1 {
 column-span: all;
}

.homepage article p {
    display: inline;
}


.files  {
margin: 0;
padding: 0;
list-style: none;
}
.files li h3 {

background: #565656;
color: #fff;
padding: .5rem;
}

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1, .logo {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
    
    
}

@media (max-width: var(--breakpoint-xs)) {
    h1, .logo {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
    
    .homepage article {

column-count: 1;

}
    
}

@media (max-width: var(--breakpoint-menu)) {
    
    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
    }
}
